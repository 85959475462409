
import { Component, Prop, Vue } from 'vue-property-decorator';

export class TabItem {
  label: string;
  icon: string;

  constructor(label = '', icon = '') {
    this.label = label;
    this.icon = icon;
  }
}

@Component<TabsComponent>({})
export default class TabsComponent extends Vue {
  @Prop({
    default: () => []
  })
  private readonly tabs!: TabItem[];

  @Prop({
    default: () => -1
  })
  private readonly defaultTabIndex!: number;

  private activeTab = -1;

  private setActiveTab(index: number) {
    if (index == this.activeTab) {
      this.activeTab = -1;
      this.$emit('tabSelected', '');
    } else {
      this.activeTab = index;
      this.$emit('tabSelected', this.tabs[index]);
    }
  }

  mounted() {
    this.activeTab = this.defaultTabIndex;
  }
}
