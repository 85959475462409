var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex mb-4 border-b" },
    [
      _vm._l(_vm.tabs, function (tab, index) {
        return _c(
          "button",
          {
            key: index,
            staticClass:
              "px-4 pb-2 border-b-4 border-transparent cursor-pointer focus:outline-none",
            class: { "border-blue-500": index === _vm.activeTab },
            on: {
              click: function ($event) {
                return _vm.setActiveTab(index)
              },
            },
          },
          [_vm._v(" " + _vm._s(tab.label) + " ")]
        )
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }