var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.recruiter
    ? _c(
        "section",
        { staticClass: "p-4 bg-gray-100" },
        [
          _c(
            "h3",
            { staticClass: "my-4 ml-4" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: _vm.Routes.FLIGHT_CHIEF_RECRUITER_COLLECTION },
                  },
                },
                [_vm._v(" Recruiter flight chief")]
              ),
              _vm._v(
                " / " +
                  _vm._s(_vm.recruiter.identificationInformationFirstname) +
                  " " +
                  _vm._s(_vm.recruiter.identificationInformationLastname) +
                  " "
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "p-4 mt-8 bg-white border border-gray-200 rounded" },
            [
              _c("tabs-component", {
                staticClass: "w-full",
                attrs: { defaultTabIndex: 1, tabs: _vm.tabs },
                on: { tabSelected: _vm.tabSelectionHandler },
              }),
              _c("legacy-table-component", {
                attrs: {
                  data: _vm.tableData,
                  isScrollable: true,
                  isSortable: true,
                  isFilterable: true,
                  tableConfigurations: _vm.tableConfigurations,
                  initialColumnOrder:
                    _vm.applicantCollectionTableColumnOrderConfig,
                  isColumnEditable: true,
                  localStorageKey:
                    "flight-chief-recruiter-submitted-applicants-table",
                },
                on: { cellEvent: _vm.cellEventHandler },
              }),
            ],
            1
          ),
          _c("create-applicant-modal", {
            ref: "createApplicantModal",
            attrs: { manager: _vm.recruiter.id, size: "small" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }