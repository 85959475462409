
import IconComponent from '@/components/IconComponent.vue';
import CreateApplicantModal from '@/components/Modals/Applicant/CreateApplicantModalComponent.vue';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import TabsComponent, {
  TabItem
} from '@/models/Table/Legacy/LegacyTabsComponent.vue';
import { ETableTabItemTypes } from '@/enums/ETableTabItemTypes';
import { Application } from '@/models/Entities/Application/Application';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { Recruiter } from '@/models/Entities/Personas/Recruiter';
import {
  applicantCollectionTableColumnOrderConfig,
  applicantTableConfigurations
} from '@/models/Table/Configurations/ApplicantTableConfiguration';
import { TableConfigurations } from '@/models/Table/Legacy/LegacyTable';
import Routes from '@/router/Routes';
import ApplicationService from '@/services/application.service';
import { Component, Prop, Vue } from 'vue-property-decorator';

class TableViewModel {
  id!: number;
  firstName!: string | null;
  middleName!: string | null;
  lastName!: string | null;
  email!: string | null;
  city!: string | null;
  zipCode!: string | null;
  homePhone!: number;
  socialSecurityNumber!: string | null;
  workPhone!: number | null;
  applications!: Application[];
  patchScore!: number;

  constructor(
    applicant: Applicant,
    applications: Application[],
    latestApplication: Application | null
  ) {
    this.id = applicant.id;
    this.firstName = applicant.identificationInformationFirstname;
    this.middleName = applicant.identificationInformationMiddlename;
    this.lastName = applicant.identificationInformationLastname;
    this.email = applicant.contactInformationEmail;
    this.city = applicant.contactInformationCity;
    this.zipCode = applicant.contactInformationZipcode;
    this.homePhone = applicant.contactInformationHomephone;
    this.workPhone = applicant.contactInformationWorkphone;
    this.applications = applications;
    this.socialSecurityNumber =
      applicant.identificationInformationSocialsecuritynumber;
    this.patchScore = latestApplication ? latestApplication.patchScore : 0;
  }
}

@Component<FlightChiefRecruiterSubmittedApplicantsPage>({
  components: {
    CreateApplicantModal,
    TabsComponent,
    LegacyTableComponent,
    IconComponent
  }
})
export default class FlightChiefRecruiterSubmittedApplicantsPage extends Vue {
  @Prop()
  readonly recruiter!: Recruiter;

  readonly Routes = Routes;
  tableConfigurations: TableConfigurations = applicantTableConfigurations;
  applicantCollectionTableColumnOrderConfig =
    applicantCollectionTableColumnOrderConfig;
  applicationsByApplicant = new Map();
  models: TableViewModel[] = [];

  $refs!: {
    createApplicantModal: CreateApplicantModal;
  };

  async created(): Promise<void> {
    for (let i = 0; i < this.recruiter.candidates.length; i++) {
      const applicant = this.recruiter.candidates[i];
      this.applicationsByApplicant.set(
        applicant,
        await this.getAllApplications(applicant)
      );
    }
    this.models = await this.getAllModels();
  }

  get tableData(): TableViewModel[] {
    return this.models;
  }

  tabSelectionHandler(payload: TabItem) {
    switch (payload.label) {
      case ETableTabItemTypes.OPEN_APPLICANTS: {
        this.$router.push({
          name: Routes.FLIGHT_CHIEF_RECRUITER_DETAILS,
          params: { id: String(this.recruiter.id) }
        });
        break;
      }
      case ETableTabItemTypes.SUBMITTED_APPLICANTS: {
        this.$router.push({
          name: Routes.FLIGHT_CHIEF_RECRUITER_APPLICANTS_SUBMITTED,
          params: { id: String(this.recruiter.id) }
        });
        break;
      }
      case ETableTabItemTypes.ARCHIVED_APPLICANTS: {
        break;
      }
      default: {
        break;
      }
    }
  }

  get tabs(): TabItem[] {
    return [
      new TabItem(ETableTabItemTypes.OPEN_APPLICANTS),
      new TabItem(ETableTabItemTypes.SUBMITTED_APPLICANTS),
      new TabItem(ETableTabItemTypes.ARCHIVED_APPLICANTS)
    ];
  }

  async cellEventHandler(column: string, row: Applicant, payload: unknown) {
    if (typeof payload == 'boolean') return;
    if (column === 'lastName') {
      const { id } = row;
      if (id) {
        this.$router.push({
          name: Routes.APPLICANT_DETAILS,
          params: { id: String(id) }
        });
      }
    }
  }

  async getAllApplications(applicant: Applicant): Promise<Application[]> {
    // THIS IS GOING AWAY, WILL BE TICKETS
    return await this.$store.dispatch('applicantModule/findApplications', {
      archived: true,
      candidateIds: [applicant.id]
    });
  }

  async getAllModels(): Promise<TableViewModel[]> {
    const applicants = this.recruiter.candidates.filter((applicant) => {
      const applications = this.applicationsByApplicant.get(applicant);
      const latestApplication =
        ApplicationService.getLatestApplication(applications);
      const latestApplicationHasBoardId =
        latestApplication && !!latestApplication.boardId;
      return latestApplicationHasBoardId;
    });
    const models = await Promise.all(
      applicants.map(async (applicant) => {
        const applications = await this.getAllApplications(applicant);
        const acceptedApplication =
          ApplicationService.getAcceptedApplication(applications);
        return new TableViewModel(applicant, applications, acceptedApplication);
      })
    );
    return models;
  }
}
